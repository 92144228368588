import React from 'react';
import './case-study.css';

interface CaseStudy {
    title: string;
    desc: string;
}

function CaseStudy({ data }: { data: CaseStudy[] }) {
    return (
        <div className="resource-case-study">
            <div className='case-study-container'>
                <div className="case-study">
                    {data.slice(0, 2).map((item, index) => (
                        <div className="case-study-box">
                            <p className="case-title">{item.title} </p>
                            <p className="case-desc" dangerouslySetInnerHTML={{ __html: item.desc }}>
                            </p>
                        </div>
                    ))}
                </div>
                <div className="case-study">
                    {data.slice(2, 4).map((item, index) => (
                        <div className="case-study-box">
                            <p className="case-title">{item.title} </p>
                            <p className="case-desc" dangerouslySetInnerHTML={{ __html: item.desc }}>
                            </p>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
}

export default CaseStudy;
