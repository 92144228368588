import React from 'react';
import './resource-hero.css';
import cority from '../../../assets/resources/cority.png';
import chris from '../../../assets/resources/Chris slaugh.png';
import quotation from '../../../assets/resources/quotation mark.png';
import { StaticImage } from 'gatsby-plugin-image';

interface Author {
    image: string;
    name: string;
    title: string;
    quote: string;
}

interface Stat {
    percentage: string;
    title: string;
}

interface Count {
    num: string;
    title: string;
}

interface ResourceData {
    companyLogo: string;
    title: string;
    author: Author;
    stats: Stat[];
    pdf: string;
    ctaTitle: string;
    count: Count[];
    countColor: string;
    isdownload: boolean;
}

function ResourceHero({ data }: { data: ResourceData }) {
    return (
        <div className="resource-hero">
            <div className="resource-hero-top">
                <div className="resource-left">
                    <div className="resource-textbox">
                        <img src={data.companyLogo} alt="Cority" />
                        <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                    </div>
                    <div className="resource-author">
                        <div className="resource-author-image">
                            <img src={data.author.image} alt="Chris Slaugh" />
                        </div>
                        <div className="author-txt-box">
                            <div className="author-title">
                                <p className="author-name" dangerouslySetInnerHTML={{ __html: data.author.name }}></p>
                                <p className="author-desc"> {data.author.title} </p>
                            </div>
                            <div className="author-quote">{`"${data.author.quote}"`}</div>
                        </div>
                    </div>
                </div>
                <div className="resource-right">
                    <div className="resource-stats-section">
                        {data.stats.map((item, i) => (
                            <div className="resource-stat">
                                <p className="stat-percentage">{item.percentage}</p>
                                <p className="stat-title">{item.title}</p>
                            </div>
                        ))}
                    </div>
                    {data?.isdownload ? (
                        <a href={data.pdf} className="download-button" dangerouslySetInnerHTML={{ __html: data.ctaTitle }} download></a>
                    ) : (
                        <a href={data.pdf} className="download-button" dangerouslySetInnerHTML={{ __html: data.ctaTitle }}></a>
                    )}
                </div>
            </div>
            <div className="resource-hero-bottom">
                {data.count.map((item, i) => (
                    <div className="resource-stats-two">
                        <p className="stat-percentage" style={{ color: data.countColor }}>
                            {item.num}
                        </p>
                        <p className="stat-title"> {item.title} </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ResourceHero;
